// COLORS

$black: #000;
$white: #fff;
$blue: #E0EFFC;
$font: #282828;
$orange: #f15a25;
$green: #549625;
$fontd: #045184;
$fontl: #3D9BE9;
$gray: #636769;
