.btn {
    display: inline-flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transition: all .3s;
    cursor: pointer;
    line-height: 1;
    text-decoration: none;
    font-size: 16px;
    padding: 22px 33px;
}

.btn-gold {
    background: gold;
    border: 1px solid gold;
    color: #fff;

    &:hover {
        background: lighten(gold, 5%);
    }
}

.btn-white {

    border: 2px solid #fff;
    color: #fff;

    &:hover {
        background: #ffffff25;
        color: #fff;
    }
}

.btn-blue {

    border: 2px solid #547fcf;
    color: #547fcf;

    &:hover {
        background: #547fcf6a;
        color: #547fcf;
    }
}

.btn-transparent {
    background: transparent;

}