.bgmain {
    background: url(../bg1.webp);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 200px 0 200px 0;
    text-align: center;

    &__h1 {
        font-size: 62px;
        line-height: 1.4;
        font-weight: 600;
        margin-bottom: 20px;
        color: #fff;

    }

    p {
        color: #fff;
        line-height: 1.3;
        font-size: 24px;
        margin-bottom: 30px;
    }


}
.bgmain2{
    background: url(../bg2.webp);
    padding: 400px 0 20px 0;
    text-align: center;
    height: 100%;

}
@media only screen and (max-width : 768px) {
    .bgmain2{
        padding: 200px 0 20px 0;
    
    }

}
@media only screen and (max-width : 576px) {
    .bgmain {
        padding: 100px 0 100px 0;

        &__h1 {
            font-size: 34px;
        }

        p {
            font-size: 18px;
        }

    }
    .bgmain2 {
        background-color: #0000005f;
        background-blend-mode: color;
    }

}
.partners{
    padding: 100px 0;
    background: $blue;
}
.heading-title{
    font-weight: 700;
    color: $gray;
    font-size: 38px;
    margin-bottom: 100px;
}
.partners__wrp{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    img{
        margin: 30px;

    }
}

@media only screen and (max-width : 578px) {
    .heading-title{

        font-size: 20px;
        margin-bottom: 100px;
        margin-bottom: 30px;
    }
    .partners{
        padding: 50px 0;
        background: $blue;
    }
}