
.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
   
}


.footer {
    margin-top: auto;
    background: #313538;
    padding: 40px 0;
    color: #fff;
    &__title{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 40px;
    }
    a{
        color: #fff;
        opacity: .8;
        display: block;
        margin: 20px 0;
        &:hover{
            opacity: 1;
        }
    }
    hr{
        margin-top: 30px;
    }
    &__bottom{
        padding: 20px 0 20px 0;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        opacity: .6;
        flex-wrap: wrap;
    }
    &__bottom-item{
        margin: 15px 0;
    }
}
