.slider-wrp{
    position: relative;
}
.main-slider {
    width: 100%;

}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 400px;
}

.swiper-slide {
    position: relative;
   
}

.slider__caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    
}

.main-prev{
 left: 10%;
}
.main-next{
 right: 10%;
}
.swiper-button-next::after, .swiper-button-prev::after {
    font-family: swiper-icons;
    font-size: 16px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
    padding: 20px 20px;
    background: #ffffff37;
    color: #fff;
  }
  .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: 50%;
    background: #fff;

  }

  .slider__h1 {
    font-size: 38px;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fff;

}

.slider__p {
    color: #fff;
    line-height: 1.3;
    font-size: 24px;
    margin-bottom: 30px;
}
.slider__caption{
    padding: 0 550px;
}
@media only screen and (max-width : 1400px) {
    .slider__caption{
        padding: 0 0;
    }
}


@media only screen and (max-width : 576px) {
    .slider__h1 {
        font-size: 20px;
     
    
    }
    .main-prev, .main-next{
        display: none;
    }

}