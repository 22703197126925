.header__wrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

@media only screen and (max-width: 576px) {
  .header__menu {
    padding: 0 40px;
  }
}
.btn {
  display: inline-flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.3s;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  font-size: 16px;
  padding: 22px 33px;
}

.btn-gold {
  background: gold;
  border: 1px solid gold;
  color: #fff;
}
.btn-gold:hover {
  background: #ffdb1a;
}

.btn-white {
  border: 2px solid #fff;
  color: #fff;
}
.btn-white:hover {
  background: rgba(255, 255, 255, 0.1450980392);
  color: #fff;
}

.btn-blue {
  border: 2px solid #547fcf;
  color: #547fcf;
}
.btn-blue:hover {
  background: rgba(84, 127, 207, 0.4156862745);
  color: #547fcf;
}

.btn-transparent {
  background: transparent;
}

.bgmain {
  background: url(../bg1.webp);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 200px 0 200px 0;
  text-align: center;
}
.bgmain__h1 {
  font-size: 62px;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
}
.bgmain p {
  color: #fff;
  line-height: 1.3;
  font-size: 24px;
  margin-bottom: 30px;
}

.bgmain2 {
  background: url(../bg2.webp);
  padding: 400px 0 20px 0;
  text-align: center;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .bgmain2 {
    padding: 200px 0 20px 0;
  }
}
@media only screen and (max-width: 576px) {
  .bgmain {
    padding: 100px 0 100px 0;
  }
  .bgmain__h1 {
    font-size: 34px;
  }
  .bgmain p {
    font-size: 18px;
  }
  .bgmain2 {
    background-color: rgba(0, 0, 0, 0.3725490196);
    background-blend-mode: color;
  }
}
.partners {
  padding: 100px 0;
  background: #E0EFFC;
}

.heading-title {
  font-weight: 700;
  color: #636769;
  font-size: 38px;
  margin-bottom: 100px;
}

.partners__wrp {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.partners__wrp img {
  margin: 30px;
}

@media only screen and (max-width: 578px) {
  .heading-title {
    font-size: 20px;
    margin-bottom: 100px;
    margin-bottom: 30px;
  }
  .partners {
    padding: 50px 0;
    background: #E0EFFC;
  }
}
.slider-wrp {
  position: relative;
}

.main-slider {
  width: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 400px;
}

.swiper-slide {
  position: relative;
}

.slider__caption {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.main-prev {
  left: 10%;
}

.main-next {
  right: 10%;
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-family: swiper-icons;
  font-size: 16px;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  padding: 20px 20px;
  background: rgba(255, 255, 255, 0.2156862745);
  color: #fff;
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: 50%;
  background: #fff;
}

.slider__h1 {
  font-size: 38px;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
}

.slider__p {
  color: #fff;
  line-height: 1.3;
  font-size: 24px;
  margin-bottom: 30px;
}

.slider__caption {
  padding: 0 550px;
}

@media only screen and (max-width: 1400px) {
  .slider__caption {
    padding: 0 0;
  }
}
@media only screen and (max-width: 576px) {
  .slider__h1 {
    font-size: 20px;
  }
  .main-prev, .main-next {
    display: none;
  }
}
.equip p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  padding: 7px 0;
  color: #045184;
}
.equip h1 {
  color: #0340B2;
  font-weight: 700;
  line-height: 1.4;
  font-size: 32px;
}
.equip h2 {
  color: #547fcf;
  font-weight: 700;
  line-height: 1.4;
  font-size: 26px;
}
.equip__title {
  font-size: 28px;
  font-weight: 700;
  color: #636769;
  margin-bottom: 20px;
}
.equip__subtitle {
  font-size: 22px;
  font-weight: 700;
  color: #636769;
  margin-bottom: 20px;
}
.equip ul {
  padding-left: 20px;
  list-style-type: disc;
}
.equip ul li {
  padding: 20px 0;
  line-height: 1.3;
  color: #045184;
  font-size: 18px;
}
.equip__btn {
  margin: 40px 0 40px 0;
}
.equip .top-ul {
  margin-bottom: 30px;
}
.equip .top-ul li {
  padding: 4px 0;
  line-height: 1.3;
  color: #045184;
  font-size: 18px;
}
.equip__block {
  margin-bottom: 30px;
}

.blue-section {
  padding: 40px 0;
  background: #E0EFFC;
}

.white-section {
  padding: 40px 0;
  background: #fff;
}

.content {
  background: #E0EFFC;
  padding: 40px 0;
}
.content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  padding: 7px 0;
  color: #045184;
}
.content table {
  margin: 30px 0;
}
.content td,
.content th {
  padding: 20px 5px;
  line-height: 1.4;
}

.partnery img {
  margin-bottom: 40px;
}

.heading {
  font-size: 38px;
  font-weight: 700;
  color: #636769;
  margin-bottom: 40px;
}

.contacts__block {
  margin-bottom: 40px;
}

@media only screen and (max-width: 576px) {
  .content p,
  .content table {
    font-size: 14px;
  }
  .equip p,
  .equip table {
    font-size: 14px;
  }
  .equip h1 {
    font-size: 22px;
  }
  .equip ul {
    padding-left: 20px;
  }
  .equip ul li {
    padding: 10px 0;
    line-height: 1.3;
    font-size: 14px;
  }
  .equip .top-ul li {
    font-size: 14px;
  }
  .equip__title {
    font-size: 22px;
  }
}
.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  margin-top: auto;
  background: #313538;
  padding: 40px 0;
  color: #fff;
}
.footer__title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 40px;
}
.footer a {
  color: #fff;
  opacity: 0.8;
  display: block;
  margin: 20px 0;
}
.footer a:hover {
  opacity: 1;
}
.footer hr {
  margin-top: 30px;
}
.footer__bottom {
  padding: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  opacity: 0.6;
  flex-wrap: wrap;
}
.footer__bottom-item {
  margin: 15px 0;
}

.nav__list {
  display: flex;
  align-items: center;
  margin: 0;
}

.nav__item:not(:last-child) {
  margin-right: 40px;
}

.nav__item {
  position: relative;
  padding: 15px 0;
}

.nav__link {
  color: #045184;
  font-size: 18px;
}
.nav__link:hover {
  color: #3D9BE9;
}

.nav__link--drop::after {
  content: "";
  width: 7px;
  height: 7px;
  background-image: url("../img/download.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  margin-left: 5px;
  transition: transform 0.3s;
}

.nav__list--dropdown {
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 100%;
  display: block;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.07);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  width: 280px;
  z-index: 90;
}

.dropdown-submenu {
  display: flex;
  align-items: initial;
}

.dropdown-submenu__item {
  flex-grow: 1;
  min-width: 200px;
  padding: 0px 30px;
}

.dropdown-submenu__list {
  padding: 0;
}

.dropdown-submenu__item:not(:last-child) {
  border-right: 1px solid #bfbfbf;
}

.dropdown-submenu__item:first-child {
  padding-left: 0;
}

.dropdown-submenu__item:last-child {
  padding-right: 0;
}

.mobile-back {
  display: none;
}

.mobile-back__link {
  color: #282828;
  font-weight: 500;
}

.dropdown-list__item {
  border-bottom: 1px solid #ececec;
}

.dropdown-list__link {
  font-size: 15px;
  color: #282828;
  padding: 13px 20px;
  display: block;
}
.dropdown-list__link:hover {
  color: #3D9BE9;
}

.dropdown-submenu__list-item {
  margin-bottom: 10px;
}

@media (min-width: 946px) {
  .nav__link--drop:hover::after {
    transform: rotate(180deg);
    transition: all 0.3s;
  }
  .nav__item:hover .nav__list--dropdown {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s;
  }
}
.burger {
  width: 40px;
  height: 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: relative;
  display: none;
}

.burger::before,
.burger::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
}

.burger::after {
  top: auto;
  bottom: 0;
}

.burger span {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  display: inline-block;
  background-color: #000;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
}

.lock {
  overflow: hidden;
}

@media (max-width: 946px) {
  .burger {
    display: block;
  }
  .mobile-back {
    display: block;
  }
  .main-menu {
    position: fixed;
    left: 0;
    top: 0;
    max-width: 400px;
    width: 80%;
    height: 100vh;
    z-index: 100;
    box-shadow: 5px 0 15px -5px rgba(0, 0, 0, 0.8);
    background-color: #fff;
    transform: translateX(-150vw);
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
  }
  .nav__list {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    padding-top: 10%;
    position: relative;
    justify-content: flex-start;
  }
  .nav__item {
    margin-left: 0 !important;
    font-size: 20px;
    width: 100%;
    padding: 0;
    position: static;
  }
  .nav__link,
  .dropdown-submenu__list-item a {
    padding: 13px 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dropdown-submenu__list-item {
    padding: 0;
  }
  .nav__link {
    border-bottom: 1px solid #ececec;
  }
  .nav__link::after {
    transform: rotate(-90deg);
  }
  .nav__list--dropdown {
    position: absolute;
    top: 0;
    right: auto;
    left: 100%;
    box-shadow: none;
    width: 100%;
    display: none;
  }
  .dropdown-submenu__item {
    width: 100%;
    padding: 0;
    border: none !important;
  }
  .dropdown-submenu__title {
    padding: 0 30px;
  }
  .dropdown-submenu__banner {
    display: inline-block;
    padding: 20px;
  }
  .nav__list.transformation {
    transform: translateX(-100%);
    transition: all 0.3s;
  }
  .nav__list--dropdown.transformation {
    opacity: 1;
    visibility: visible;
    display: block;
    transform: none;
    background-color: #fff;
    transition: all 0.3s;
  }
  .main-menu.open {
    transform: translateX(0);
    transition: all 0.3s;
  }
  .overlay.open {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }
  .mobile-back a {
    position: relative;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .mobile-back a::before {
    content: "";
    width: 10px;
    height: 10px;
    background-image: url("../img/download.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 10px;
    transform: rotate(90deg);
  }
}
.close_btn {
  display: none;
  position: absolute;
  z-index: 60;
  right: 10px;
  top: 17px;
  cursor: pointer;
}
.close_btn .ic_close {
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 946px) {
  .open .close_btn {
    display: block;
  }
}
@media only screen and (max-width: 1200px) {
  .nav__link {
    font-size: 16px;
  }
}