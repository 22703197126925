.header{
    &__wrp{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        
    }
}
@media only screen and (max-width : 576px) {
  .header{
    &__menu{
        padding: 0 40px
    }
  }
}