.equip {

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        padding: 7px 0;
        color: $fontd;

    }

    h1 {
        color: #0340B2;
        font-weight: 700;
        line-height: 1.4;
        font-size: 32px;
    }
    h2 {
        color: #547fcf;
        font-weight: 700;
        line-height: 1.4;
        font-size: 26px;
    }

    &__title {
        font-size: 28px;
        font-weight: 700;
        color: $gray;
        margin-bottom: 20px;
        
    }

    &__subtitle {
        font-size: 22px;
        font-weight: 700;
        color: $gray;
        margin-bottom: 20px;
    }

    ul {
        padding-left: 20px;
        list-style-type: disc;

        li {
            padding: 20px 0;
            line-height: 1.3;
            color: $fontd;
            font-size: 18px;
        }
    }
    &__btn{
        margin: 40px 0 40px 0;
    }

    .top-ul {
        margin-bottom: 30px;

        li {
            padding: 4px 0;
            line-height: 1.3;
            color: $fontd;
            font-size: 18px;
        }


    }

    &__block {
        margin-bottom: 30px;
    }

}

.blue-section {
    padding: 40px 0;
    background: $blue;
}

.white-section {
    padding: 40px 0;
    background: #fff;
}

.content {
    background: $blue;
    padding: 40px 0;

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 1.4;
        padding: 7px 0;
        color: $fontd;

    }

    table {
        margin: 30px 0;
    }

    td,
    th {
        padding: 20px 5px;
        line-height: 1.4;

    }
}


.partnery {

    img {
        margin-bottom: 40px;
    }
}

.heading {
    font-size: 38px;
    font-weight: 700;
    color: $gray;
    margin-bottom: 40px;
}
.contacts__block{
    margin-bottom: 40px;
}

@media only screen and (max-width : 576px) {
    .content {

        p,
        table {
            font-size: 14px;

        }

    }

    .equip {

        p,
        table {
            font-size: 14px;

        }

        h1 {
            font-size: 22px;
        }

        ul {
            padding-left: 20px;

            li {
                padding: 10px 0;
                line-height: 1.3;
                font-size: 14px;
            }
        }

        .top-ul {

    
            li {
                font-size: 14px;
            }
    
    
        }
        &__title{
            font-size: 22px;
        }

    }


}